import React from "react";

// Customizable Area Start
import {
  Box,
  InputAdornment,
  Checkbox,
  Table,
  TableRow,
  TableContainer,
  TableHead,
  TableCell,
  Button,
} from "@material-ui/core";
import {
  GoldishSimpleButton,
  SearchTextFieldActiveBooking,
  FilterTypeButton,
  StyledEditDeleteMenu,
  FormSubmitButton,
  renderTableLoader,
  StyledPagination,
} from "../../../components/src/MuiCustomComponents";
import {
  theme as colors,
} from "../../../components/src/utils";
import { Search, FilterList, SwapVert } from "@material-ui/icons";
import {
  allFilter,
  clientUpcomingBookingTableHeaderData,
  clientPreviousBookingTableHeaderData,
  additionalComplete,
  additionalAdd,
} from "./assets";
import moment from "moment";

export interface UpcomingBookingAttributes {
  id: number;
  order_id: number;
  client: string;
  booking_no: string;
  time_to_first_leg: string;
  number_of_legs: number;
  coming_in: string;
  departing_from: string;
  departure_date: string;
  additional_details: boolean;
  booking_date: string;
  status: string;
  vendor: {
    name: string;
  };
  vendor_status: string;
}

export interface UpcomingBookingData {
  id: string;
  type: string;
  attributes: UpcomingBookingAttributes;
}
// Customizable Area End

import ClientUpcomingBookingController, {
  Props,
  configJSON,
} from "./ClientUpcomingBookingController";

export default class ClientUpcomingBooking extends ClientUpcomingBookingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderBookingTable = () => {
    const {
      upcomingBookings,
      isLoading,
    } = this.state;
    const isDataAvailable =
      upcomingBookings.length !== 0;
    return (
      <TableContainer
        className="dash_table_flights"
        style={{ marginTop: "2rem" }}
      >
        <Table
          style={{ borderCollapse: "separate" }}
        >
          {this.renderTableHeadRow()}
          <Box height={"15px"} />
          {isLoading ? (
            <TableCell
              colSpan={4}
              style={{ borderBottom: "none" }}
            >
              {renderTableLoader()}
            </TableCell>
          ) : (
            <React.Fragment>
              {isDataAvailable &&
                this.renderTableRows()}
              {!isDataAvailable && (
                this.renderNoRecordFound()
              )}
            </React.Fragment>
          )}
        </Table>
      </TableContainer>
    );
  };

  renderTableHeadRow = () => {
    const {
      applySorting,
      isUpcomingBooking,
    } = this.state;
    const tableHeaders = isUpcomingBooking
      ? clientUpcomingBookingTableHeaderData
      : clientPreviousBookingTableHeaderData;
    return (
      <TableHead>
        <TableRow
          className="dash_table_head"
          style={{ height: "auto" }}
        >
          {tableHeaders &&
            tableHeaders.map(
              ({
                title,
                value,
              }: {
                title: string;
                value: string | null;
              }) => {
                return (
                  <>
                    <TableCell
                      style={{
                        borderRadius: "0",
                      }}
                    >
                      <Box className="order_detail_table_head_cell">
                        <Box color={"#94A3B8"}>
                          {title}
                        </Box>
                        {value && (
                          <Box
                            onClick={() => {
                              this.handleSorting(
                                value
                              );
                            }}
                            id="order_detail_view_menu_sort_button"
                            data-testid="menu_sort_button"
                            className={
                              value ===
                              applySorting
                                ? "order_detail_view_menu_sorted"
                                : ""
                            }
                          >
                            <SwapVert
                              style={{
                                color:
                                  colors.GOLD_COLOR,
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                  </>
                );
              }
            )}
        </TableRow>
      </TableHead>
    );
  };

  renderTableRows = () => {
    const {
      isUpcomingBooking,
      upcomingBookings,
    } = this.state;
    return upcomingBookings.map(
      (elemData: UpcomingBookingData) => {
        const {
          attributes: {
            booking_no,
            time_to_first_leg,
            booking_date,
            number_of_legs,
            departure_date,
            departing_from,
            additional_details,
            status,
          },
        } = elemData;

        return (
          <React.Fragment key={booking_no}>
            <TableRow
              className="dash_table_row order_details_row"
              data-testid="redirect_booking"
            >
              <TableCell className="dash_rounded-row-first-cell">
                {`#` + booking_no}
              </TableCell>
              {isUpcomingBooking ? (
                <TableCell>{time_to_first_leg}</TableCell>
              ) : (
                <TableCell>
                  {moment(booking_date, "YYYY-MM-DD").format(
                    "DD/MM/YYYY"
                  )}
                </TableCell>
              )}
              <TableCell>{number_of_legs}</TableCell>
              <TableCell>{departing_from}</TableCell>
              <TableCell>
                {moment(departure_date, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )}
              </TableCell>
              <TableCell className={`dash_rounded-row-last-cell`}>
                {isUpcomingBooking ? (
                  <React.Fragment>
                    {" "}
                    {additional_details ? (
                      <Button
                        variant="outlined"
                        style={{
                          color: "#059669",
                          borderColor: "#059669",
                          textTransform: "none",
                          fontSize: "12px",
                          display: "flex",
                          fontWeight: 800,
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "8px 16px",
                          borderRadius: "8px",
                        }}
                      >
                        Additional Details Complete
                        <img
                          src={additionalComplete}
                          width={"24px"}
                          height={"24px"}
                          style={{
                            fontSize: "20px",
                            marginLeft: "8px",
                          }}
                        />
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        style={{
                          color: "#FFFFFF",
                          borderColor: "#FFE3AE",
                          textTransform: "none",
                          fontSize: "12px",
                          display: "flex",
                          fontWeight: 800,
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "8px 16px",
                          borderRadius: "8px",
                        }}
                      >
                        Add Additional Details
                        <img
                          src={additionalAdd}
                          width={"24px"}
                          height={"24px"}
                          style={{
                            fontSize: "20px",
                            marginLeft: "8px",
                          }}
                        />
                      </Button>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Box
                      className={
                        status === "completed"
                          ? "order_detail_view_paid"
                          : "order_detail_view_unpaid"
                      }
                    >
                      {status.charAt(0).toUpperCase() + status.slice(1)}
                    </Box>
                  </React.Fragment>
                )}
              </TableCell>
            </TableRow>
            <Box height={"0.5rem"} />
          </React.Fragment>
        );
      }
    );
  };

  renderNoRecordFound = () => {
    return (
      <TableRow className="dash_table_row no_result_found">
        <TableCell
          className="dash_rounded-row-first-cell dash_rounded-row-last-cell"
          colSpan={9}
          style={{ textAlign: "center" }}
        >
          No Data Found!
        </TableCell>
      </TableRow>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      anchorEl,
      appliedFilter,
      totalPages,
      page,
    } = this.state;
    return (
      <Box className="dash_main booking_main">
        <Box className="order_detail_view_heading_parent">
          <GoldishSimpleButton
            style={{
              color: colors.PRIMARY_COLOR,
              textTransform: "capitalize",
              width: "15rem",
              justifyContent: "start",
            }}
            className="cf-avinode-search"
          >
            {this.pageHeading()}
          </GoldishSimpleButton>
          <Box className="order_detail_view_search">
            <SearchTextFieldActiveBooking
              fullWidth
              data-test-id="search_field"
              placeholder={"Enter Booking ref no, client, date etc..."}
              onChange={this.handleSearchChange}
              style={{ position: "relative", left: "-55px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search style={{ color: "#757677" }} />
                  </InputAdornment>
                ),
              }}
            />
            <Box />
          </Box>
          <Box>
            <FilterTypeButton
              onClick={this.handleMenuOpen}
              data-test-id="menu_opener"
            >
              <FilterList style={{ fontSize: "2rem" }} />
            </FilterTypeButton>
          </Box>
          <StyledEditDeleteMenu
            PaperProps={{
              style: {
                width: "12rem",
                marginTop: "6.2rem",
                padding: "0px 10px",
              },
            }}
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            data-test-id="menu_closer"
            onClose={this.handleClose}
          >
            <Box className="order_detail_view_menu_box">
              {allFilter.map((elem: string) => {
                return (
                  <Box className="order_detail_view_menu_item">
                    <Checkbox
                      checked={appliedFilter.includes(elem)}
                      data-test-id="check_box"
                      style={{ color: "white" }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        this.handleCheckboxChange(
                          (event as unknown) as React.MouseEvent<
                            HTMLElement,
                            MouseEvent
                          >,
                          elem
                        )
                      }
                    />
                    <Box style={{ textTransform: "capitalize" }}>{elem}</Box>
                  </Box>
                );
              })}
              <FormSubmitButton
                className="cf_avinode_apply_filter"
                fullWidth
                onClick={this.handleApplyFilter}
                disabled={appliedFilter.length === 0}
                data-test-id="apply_filter"
              >
                {configJSON.applyFilter}
              </FormSubmitButton>
            </Box>
          </StyledEditDeleteMenu>
        </Box>
        {this.renderBookingTable()}
        <Box marginTop={"1rem"}>
          <StyledPagination
            count={totalPages}
            page={page}
            data-test-id="pagination_comp"
            onChange={(_event: React.ChangeEvent<unknown>, page: number) => {
              this.handleCurrentPage(page);
            }}
            shape="rounded"
          />
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
