import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react"
import { Message } from "../../../framework/src/Message";
import { ICountry, IState, ICity, Country, State,City} from 'country-state-city';
import {getStorageData, setStorageData, removeStorageData} from "../../../framework/src/Utilities";
import {handleLogOut, handleNavigation} from '../../../components/src/Navigator';
import { FormikProps } from 'formik';
import { getTokenInfo } from "../../../components/src/utils";
import { americanExpress, defaultCard, masterCard, visa } from "./assets";


export type PhotoType = string | null | Blob;
export interface ProfileDataType  {
      full_name:string,
      email:string | null,
      phone_number:string | null,
      preferred_departure_airport:string,
      street_address:string | null,
      zip_code:string | null,
      dietary_requirements: string | null,
      photo:string|null|Blob,
      account_id: null | string,
      countryCode: string,
      city:string,
      state:string|null,
      main_country_of_residence:string|null,
      phoneNumber:string|null,
      companyName:string,
}

export interface AdminProfileDataType  {
    full_name:string | null,
    email:string | null,
    photo:PhotoType,
    account_id: null | string,
    job_title: null | string,
    account_type: null | string,
    commission: null | string,
    job_id:null | number |string
    account_number: string

}

interface InitialCountriesStateCityData {
  country : ICountry | null,
  state : IState | null,
  city : ICity | null,
  states : IState[] | [],
  cities : ICity[] | []
}

interface RoleType  {
  role:string,
  roleValue:string
}

type DeleteAdminProfileResponseJSON = {
  data: MetaMessage
}

type MetaMessage = {
  meta: {
    message: string
  }
}

type ResetMessage = {
  data : {
    message : string
  }
}

type SuccessProfileResponse = MetaMessage & {data : {attributes : UserProfileResponse}}
type EmailFormData = {email : string}
type OTPFormData = {otpValue : string}


export type JobRoleType = {
  id: string
  type: string
  attributes: {
    job_title: string
  }
}

type ErrorType = { [key: string]: string };

type RequestType = "profileDetails" | "profilePicture" | 'full_name';
interface NewEmailResponseType {
  meta: {
      token: string; 
  };
}

interface InitialValues{
  preferredAirport : {id:string,name:string,city:string,code:string}
  optedCountry: ICountry|{name:string|null}
}

export type userProfileFormDataType = ProfileDataType & InitialValues & {countryCode : string} & {phoneNumber : string}

interface UserProfileResponse {
      email: string;
      full_name: string;
      dietary_requirements: string;
      street_address: string;
      zipcode: string;
      full_phone_number: string;
      preferred_departure_airport: string;
      photo: string; 
      account_id: string;
      account_number:string,
      main_country_of_residence: string;
      state: string;
      city: string;
      country_code: string;
      phone_number: string;
      company_name:string
  };

  export type setFieldValueType =(field: string, value: string | File|null, shouldValidate?: boolean | undefined) => void
  export type setFieldErrorType =  (field: string, message: string | undefined) => void;
  export type password_initial_values_type = {
    old_password:string,
    new_password:string,
    confirm_password:string
  }

  interface AirportType{
    
    "id": string,
    "name": string,
    "city": string,
    "country": {
        "code": string,
        "name": string,
    },
    countryCode:string,
    code:string,
    "icao": string,
}

export interface Airports{
  "id": string,
  "name": string,
  "city": string,
  countryCode:string,
  code:string,        
}




const roles = [{role:'Admin',roleValue:'admin'},{role:'Super Admin',roleValue:'super_admin'}];



// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  dietryData:string[],
  countries:null | ICountry[],
  states:[] |IState[],
  cities:[] | ICity[],
  selectedCountry: null | ICountry,
  selectedState : null | IState,
  selectedCity:null | ICity,
  profileData: ProfileDataType,
  initialCoutryStateCity : InitialCountriesStateCityData,
  photo:string | null | Blob,
  isEditProfileModalVisible:boolean,
  isEditFullNameModalVisible:boolean,
  userRole:string | null,
  isEditEmailModalOpen:boolean,
  isEditAdminModeOpen:boolean,
  focusedLabel: null | string,
  adminData : AdminProfileDataType,
  roles:RoleType[],
  jobRolesData : JobRoleType[] | [],
  isChangePasswordModalOpen:boolean,
  passwordVisiblity:{
    Old:boolean,
    New:boolean,
    Confirm:boolean
  },
  isErrorPasswordContainerVisible:boolean,
  isStatusModalVisible:boolean,
  isOtpModalOpen:boolean,
  seconds:number,
  isRunning:boolean,
  keyToken:string,
  changeEmail:string,
  statusModalContent:{
    heading:string,
    subHeading:string
  },
  isDeleteProfileModalVisible:boolean,
  isCreateNewAdmin:boolean,
  isAdminProfileUpdateRoute:boolean | string
  myTokenId:string | null
  searchedAirports:Airports[]
  airports:Airports[],
  isLoading:boolean,
  accountId:string|"",
  page:string|null,
  showPaymentModal:boolean,
  action:string,

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicBlockController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getJobTitleForSuperAdminApiId:string=''
  getUserProfileCallApiId:string = ''
  getAdminProfileCallApiId:string = ''
  updateUserProfileApiId:string = ''
  deleteUserProfileApiId:string = ''
  updateAdminProfileApiId:string = ''
  updatePasswordProfileApiId:string = ''
  deleteAdminProfilePictureApiId:string = ''
  deleteAdminProfileApiId:string = ''
  updateEmailApiId:string = ''
  updateAnotherEmailApiId:string=''
  verifyOtpApiId:string = ''
  inputFileRef:React.RefObject<HTMLInputElement> = React.createRef()
  formikForm: null | FormikProps<unknown> = null;
  adminFormik: null | FormikProps<unknown> = null;
  updatePasswordFormik: null | FormikProps<unknown> = null;
  timerID:NodeJS.Timeout | null = null;
  emailFormik:null | FormikProps<unknown> = null;
  otpFormik:null | FormikProps<unknown> = null;
  resetAdminPwApiId : string = ''
  delayTimer : NodeJS.Timeout|string="";
  getAirportsAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      photo:null,
      dietryData:['Vegan','Vegetarian','Pescatarian', 'Gluten-free', 'Dairy-free', 'I eat anything', 'Other'],
      countries:Country.getAllCountries(),
      selectedCountry: null,
      selectedState : null,
      states: [],
      cities:[],
      selectedCity:null,
      profileData:{
        full_name:"",
        email:null,
        phone_number:null,
        countryCode:"",
        preferred_departure_airport:"",
        street_address:null,
        zip_code:null,
        dietary_requirements: 'Please Specify',
        photo:null,
        account_id:null,
        city:"",
        state:null,
        main_country_of_residence:null,
        phoneNumber:null,
        companyName:"",
      },
      initialCoutryStateCity : {
        cities : [],
        states : [],
        city : null,
        country : null,
        state : null
      },
      isEditProfileModalVisible: false,
      isEditFullNameModalVisible: false,
      userRole:null,
      isEditEmailModalOpen:false,
      isEditAdminModeOpen:false,
      focusedLabel:null,
      adminData:{
        account_id:null,
        email:null,
        full_name:'',
        job_title: '',
        account_type: 'none',
        photo:null,
        commission: '',
        job_id:'none',
        account_number:''
      },
      roles:roles,
      jobRolesData:[],
      isChangePasswordModalOpen:false,
      passwordVisiblity:{
        Old:false,
        New:false,
        Confirm:false
      },
      isErrorPasswordContainerVisible:false,
      isStatusModalVisible:false,
      changeEmail:'',
      isOtpModalOpen:false,
      isRunning:false,
      keyToken:'',
      seconds:60,
      statusModalContent:{
        heading:'',
        subHeading:''
      },
      isDeleteProfileModalVisible:false,
      isCreateNewAdmin:false,
      isAdminProfileUpdateRoute:false,
      myTokenId:null,
      searchedAirports:[],
      airports:[],
      isLoading:false,
      accountId:"",
      page:null,
      showPaymentModal:false,
      action:"",
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

    // Customizable Area Start

    async componentDidMount() {
      const {location:{pathname}} = window
      this.handleProfileDataCallerFunction();
      this.handleLoggedInUserRole();
      this.handleIsMyToken();
      this.setState({page:pathname})
    }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));



    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (responseJson && responseJson.data) {

        switch (apiRequestCallId) {
          case this.getUserProfileCallApiId:
            this.handleUserProfileSuccessApiResponse(responseJson.data);
            break;

          case this.updateUserProfileApiId:
            this.handleSuccessUpdateApiResonponse(responseJson);
            break;

          case this.deleteUserProfileApiId:
            this.handleUserProfileSuccessApiResponse(responseJson.data);
            break;

          case this.getAdminProfileCallApiId:
              this.handleAdminProfileDataResponse(responseJson);
              break;  

          case this.updateAdminProfileApiId:
            this.handleEditAdminProfileResponse(responseJson);
            this.setState({isEditAdminModeOpen:false});
              break;      

          case this.deleteAdminProfilePictureApiId:
            this.handleEditAdminProfileResponse(responseJson);
              break; 
              
          case this.getJobTitleForSuperAdminApiId:    
            this.handleJobResponse(responseJson);
                  break;  

          case this.updatePasswordProfileApiId:    
            this.handleUpdatePasswordREsponse(responseJson);
                  break;                    
          
          case this.updateEmailApiId:
            this.handleEmailSuccessResponse(responseJson);
                  break;   
                  
          case this.resetAdminPwApiId:
            this.handleResetAdminPwResponse(responseJson);
            break;


          case this.updateAnotherEmailApiId:
            this.handleUpdateAnotherEmailRes(responseJson);
            break;  

          default:
            break;

    }
  }else if(responseJson.errors){
          switch(apiRequestCallId){
            case this.updateUserProfileApiId:
            this.handleFailureResponse(responseJson.errors)  
            break;

            case this.updatePasswordProfileApiId:
            this.handleFailUpdatePasswordREsponse(responseJson.errors)
            break;

            case this.updateEmailApiId:
            this.handleEmailFailureResponse(responseJson.errors) 
            break;
            
            case this.verifyOtpApiId:
            this.handleOtpFailureResponse(responseJson.errors)  
            break;

            case this.updateAdminProfileApiId:
              this.handleCreateNewAdminFailureResponse(responseJson.errors)  
              break;

            case this.updateAnotherEmailApiId:
            this.handleupdateAnotherEmailFailRes(responseJson.errors);
            break;

            default:

          }

  }else if(responseJson.message){
    this.handleMessageHandler(apiRequestCallId,responseJson);
  }
  else if(responseJson.airports){
    this.handleAirportsResponse(responseJson.airports,false)
  }

    // Customizable Area End
     }
  }
    // Customizable Area End

    // Customizable Area Start
      handleIsMyToken=async()=>{
        const token = await getStorageData('token')
        const {id} = getTokenInfo(token);
        this.setState({myTokenId:id});
      }

      handleMessageHandler=(apiRequestCallId:string,responseJson:{message:string})=>{
        if(apiRequestCallId === this.verifyOtpApiId){
          this.handleOtpSuccessResponse(responseJson)
        }else if(apiRequestCallId === this.deleteAdminProfileApiId){
            this.handleAdminProfileDeleteResponse(responseJson);
        }
      }

      resendClick=()=>{
        this.updateEmail({email:this.state.changeEmail});
      }

      resetTimer=()=>{
        this.setState({seconds:60})
        this.startTimer();
      }

      startTimer = () => {
        if (!this.state.isRunning) {
          this.timerID = setInterval(() => {
            this.setState((prevState) => ({
              seconds: prevState.seconds - 1
            }), () => {
              if (this.state.seconds === 0) {
                clearInterval(this.timerID as NodeJS.Timeout);
                this.setState({ isRunning: false });
              }
            });
          }, 1000);
          this.setState({ isRunning: true });
        }
      };

      setChangeEmail=(values:EmailFormData)=>{
        this.setState({changeEmail:values.email});
      }

      updateEmailHandler=(values:EmailFormData)=>{
        const {myTokenId,userRole,adminData:{account_id}} = this.state;
        const isMyAccount = myTokenId === account_id;
        const isUser = userRole === "user"
        
        if(isUser||isMyAccount) this.updateEmail(values);
        else this.updateAnotherAdminEmail(values);
      }

      updateEmail=async(values:EmailFormData)=>{
        const headers = {
          token : await getStorageData('token'),
          'Content-type':configJSON.contentTypeApiGetUserProfile
        }

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateEmailApiId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.updateEmailEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.callTypeApiValidateMobileNo
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(this.handleEmailData(values)) 
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      updateAnotherAdminEmail=async(values:EmailFormData)=>{
        const {adminData:{account_id}} = this.state;
        const headers = {
          token : await getStorageData('token'),
          'Content-type':configJSON.contentTypeApiGetUserProfile
        }
        const formData = {id : account_id,new_email : values.email}
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateAnotherEmailApiId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.updateAnotherEmailEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.updateUserApiType
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(formData) 
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      verifyOtp=async(values:OTPFormData)=>{
        const headers = {
          token : await getStorageData('token'),
          'Content-type':configJSON.contentTypeApiGetUserProfile
        }

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.verifyOtpApiId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.verifyOtpEndpoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.callTypeApiValidateMobileNo
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(this.handleOtpData(values)) 
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      handleOtpData=(values:OTPFormData)=>{
        return {
            data:{
              attributes:{
                token : this.state.keyToken,
                otp : values.otpValue
              }
            }
        }
      }

      handleOtpFailureResponse=(responseJson:ErrorType[])=>{
          (this.otpFormik as FormikProps<unknown>).setFieldError('otpValue',responseJson[0].otp + '*')
      }

      handleCreateNewAdminFailureResponse=(error : {account:string}[])=>{
        (this.adminFormik as FormikProps<unknown>).setFieldError('email',error[0].account);
      }

      handleCancel=()=>{
        const {isCreateNewAdmin} = this.state;
        if(isCreateNewAdmin){
          this.handleNavigateToAdminList();
        }else{
          this.setState({isEditAdminModeOpen:false});
        }
      }

      handleNavigateToAdminList=()=>{
        handleNavigation('/AdminList',this.props,this.send);
      }

      handleOtpSuccessResponse=(responseJson:{message:string})=>{
        const {userRole,profileData,changeEmail} = this.state;
        const isUser = userRole === "user"
        if(responseJson.message === 'Email Updated Successfully'){
          this.handleisOtpModalOpen(false);
          this.handleStatusVisible(true);
          this.handleStatusModalContent('Email','email');
          if(isUser){
            this.setState({profileData:{...profileData,email:changeEmail}})
          }
          else{
            this.setState({adminData:{...this.state.adminData,email:changeEmail}})
          }
        }
      }

      handleisOtpModalOpen=(isOpen:boolean)=>{
        this.setState({isOtpModalOpen:isOpen});
      }

      handleStatusModalContent=(heading:string,subHeading:string)=>{
        this.setState({
          statusModalContent:{
            heading:`${heading} Change Successful`,
            subHeading:`Your ${subHeading} has been changed successfully`
          }
        })
      }

      handleEmailFailureResponse=(responseJson:ErrorType[])=>{
        (this.emailFormik as FormikProps<unknown>).setFieldError('email',responseJson[0].account + '*')
      }

      handleEmailSuccessResponse=(responseJson:NewEmailResponseType)=>{
        if(responseJson.meta.token){
          this.setState({isEditEmailModalOpen:false,isOtpModalOpen:true,keyToken:responseJson.meta.token});
          this.resetTimer();
        }
      }

      handleAdminProfileDeleteResponse=(responseJson:{message:string})=>{
        const {message} = responseJson;
        const {myTokenId,adminData:{account_id}} = this.state;
        if(message === 'User account has been deleted successfully'){
          const isMyAccount = myTokenId == account_id;
          this.setState({isDeleteProfileModalVisible:false});
          !isMyAccount ? this.handleNewAdminCreatedSuccessResponse() : handleLogOut(undefined,this.props,this.send);
        }
      }

      handleEmailData = (values:EmailFormData)=>{
        const {email} = values;
        return {
          data:{
            attributes:{
              email
            }
          }
        } 
      }

  



    handleEditAdminProfileResponse=(responseJson:{data :{attributes: AdminProfileDataType}}) =>{
      const {isCreateNewAdmin} = this.state
      if(isCreateNewAdmin){
        this.handleNewAdminCreatedSuccessResponse();

      }else{
        this.handleAdminProfileDataResponse(responseJson);
        this.handleIsAdminProfile() === null && this.handleTriggerNavbar(responseJson.data);
        this.handleTriggerAlertMessage();
        this.setState({isEditProfileModalVisible:false});
      }
    }

    handleNewAdminCreatedSuccessResponse=()=>{
      this.handleTriggerAlertMessage();
      this.handleNavigateToAdminList();
    }

    handleJobResponse=(responseJson:{data : JobRoleType[]})=>{
      const {data} = responseJson;
      if(data){
        this.setState({jobRolesData:data});
      }
    }

    handleUpdatePasswordREsponse=(responseJson:MetaMessage)=>{
      if(responseJson.meta.message === 'Password Updated Successfully'){
        this.handleStatusModalContent('Password','password')
        this.setState({isStatusModalVisible:true,isChangePasswordModalOpen:false});
      }
    }


    handleFailUpdatePasswordREsponse=(responseJson:ErrorType[])=>{
      if(responseJson[0].current_password === 'Wrong Current Password'){
        (this.updatePasswordFormik as FormikProps<unknown>).setFieldError("old_password", 'Old Password is wrong*');
      }
    }



    handleUserProfileSuccessApiResponse=(responseJson:{attributes:UserProfileResponse})=>{
      const {attributes:{email,full_name,dietary_requirements,street_address,zipcode,full_phone_number,preferred_departure_airport,photo,account_id,main_country_of_residence, state, city, country_code, phone_number,company_name,account_number}} = responseJson;

      this.setState({profileData:{email,full_name,street_address,dietary_requirements,zip_code:zipcode,
        city,state,phone_number:full_phone_number,companyName:company_name,preferred_departure_airport,photo,account_id,main_country_of_residence, 
        countryCode:country_code,phoneNumber:phone_number,},photo,isEditProfileModalVisible:false,isEditAdminModeOpen:false,isLoading:false,accountId:account_number});
      this.handleCountryStateCityOnMount(main_country_of_residence,state,city);
      this.handleTriggerNavbar(responseJson);
    }

    handleCountryChange=(newValue:ICountry)=>{
          this.setState({
            states: State.getStatesOfCountry(newValue.isoCode),
            cities: [],
        })
    }

    handleAdminProfileDataResponse=(responseJson:{data :{attributes: AdminProfileDataType}})=>{
      if(responseJson.data){
        this.handleAdminProfileDataSuccessResponse(responseJson);
      }
    }

    handleAdminProfileDataSuccessResponse=(responseJson:{data :{attributes: AdminProfileDataType}})=>{
      const {data:{attributes:{account_id,email,full_name,job_id,account_type,commission,photo,job_title,account_number}}} = responseJson;
      this.setState({adminData:{account_id,email,account_type,commission,full_name,job_title,photo,job_id,account_number}})
    }

    handleTriggerNavbar = async(responseJson : {attributes : AdminProfileDataType | UserProfileResponse})=>{
      const {attributes:{full_name, photo}} = responseJson;
       await setStorageData('name',full_name);
      if(photo){
       await setStorageData('photo',photo);
      }else{
       await removeStorageData('photo');
      }
      const senderTo = new Message(getName(MessageEnum.NavigationMessage));
      this.send(senderTo);
    };

    handleTriggerAlertMessage=(message="Changes saved successfully")=>{
      const messageTriger = new Message(getName(MessageEnum.AlertMessage));
      messageTriger.addData(getName(MessageEnum.AlertBodyMessage), message);
      this.send(messageTriger);
    }

    handleStateChange=(newValue:IState)=>{
        this.setState({
          cities: City.getCitiesOfState(
              newValue.countryCode,
              newValue.isoCode,
          ),
    })}


      handleCountryStateCityOnMount=(countryName:string,stateName:string,cityName:string)=>{
        const selectedCountry = Country.getAllCountries().find(country => (country).name === countryName) || null;
        const states = State.getStatesOfCountry(selectedCountry?.isoCode);
        const selectedState = states.find(state => (state).name === stateName) || null;
        const cities = City.getCitiesOfState(selectedState?.countryCode as string,selectedState?.isoCode as string);
        const selectedCity = cities.find(city => (city).name === cityName) || null;
        this.setState({selectedCity,selectedCountry,selectedState,cities,states,initialCoutryStateCity:{cities,states,country:selectedCountry,state:selectedState,city: selectedCity}});
      }

      handleResetForm = ()=>{
        const {initialCoutryStateCity:{cities,city,country,state,states}} = this.state;
        this.setState({selectedCountry:country,selectedCity:city,selectedState:state,states,cities,isEditAdminModeOpen:false});
      }

      handleFormData = (values:userProfileFormDataType)=>{
        let formData = new FormData();
        values.dietary_requirements && formData.append("data[attributes][dietary_requirements]",values.dietary_requirements);
        values?.optedCountry?.name && formData.append("data[attributes][main_country_of_residence]",values.optedCountry.name);
        values.state && formData.append("data[attributes][state]",values?.state || "");
        values?.companyName !== null && formData.append("data[attributes][company_name]",values?.companyName || "");
        formData.append("data[attributes][city]",values?.city || "");
        values?.zip_code !== null && formData.append("data[attributes][zipcode]",values.zip_code.trim());
        values?.street_address !== null && formData.append("data[attributes][street_address]",values.street_address.trim());
        formData.append('[data][attributes][full_name]',values.full_name);
        if(this.state.profileData.phone_number !== `${values.countryCode}${values.phoneNumber}`){
          formData.append("data[attributes][country_code]",values.countryCode);
          formData.append("data[attributes][phone_number]",values.phoneNumber);
        }
        values.photo&& typeof(values.photo) !== "string" && formData.append("data[attributes][photo]",values.photo)
        formData.append("data[attributes][preferred_departure_airport]",values.preferredAirport.city ? `${values.preferredAirport.name}, ${values.preferredAirport.city} - ${values.preferredAirport.code}`:values.preferredAirport.name)
        return formData;
      }

      handleSuccessUpdateApiResonponse=(responseJson:SuccessProfileResponse)=>{
        if(responseJson.meta.message === 'Profile Updated Successfully'){
          this.handleUserProfileSuccessApiResponse(responseJson.data);
          this.handleTriggerAlertMessage();
        }
      }

      handleProfilePictureModalClose=(isOpen:boolean)=>{
        this.setState({isEditProfileModalVisible:isOpen});
      }

      handleEditFullNameModalClose=(isOpen:boolean)=>{
        this.setState({isEditFullNameModalVisible:isOpen});
      }

      handleEditEmailModalOpen=(isOpen:boolean,password=false)=>{
        if(password) this.setState({isChangePasswordModalOpen:isOpen})
        else  this.setState({isEditEmailModalOpen:isOpen})
      }

      handleDeleteAccountModalVisible=(isOpen:boolean)=>{
        this.setState({isDeleteProfileModalVisible:isOpen});
      }

      handleChangePasswordModalOpen=(isOpen:boolean)=>{
        this.setState({isChangePasswordModalOpen:isOpen});
      }

      handleOffPasswordVisibility=()=>{
        this.setState({passwordVisiblity:{Confirm:false,New:false,Old:false}});
      }

      handleErrorContainerVisible=(isOpen:boolean)=>{
        this.setState({isErrorPasswordContainerVisible:isOpen})
      }

      handleStatusVisible=(isOpen:boolean)=>{
        this.setState({isStatusModalVisible:isOpen})
      }

      handleVisibilityPassword=(keyName:string,value:boolean)=>{
        this.setState({passwordVisiblity:{...this.state.passwordVisiblity,[keyName]:value}});
      }

      handleEditAdminProfileMode=(isOpen:boolean)=>{
        this.setState({isEditAdminModeOpen:isOpen});
      }

      handleActiveFocusedLabelProfie=(label:string | null)=>{
        this.setState({focusedLabel:label})
      }

      handleSaveAdminProfileChanges=()=>{
        this.adminFormik?.handleSubmit()
      }

      handleFailureResponse = (errors:ErrorType[])=>{
        this.setState({isLoading:false})
        if(errors[0].full_phone_number){
          (this.formikForm as FormikProps<unknown>).setFieldError("phoneNumber", errors[0].full_phone_number);
        }else if(errors[0].phone_number){
          (this.formikForm as FormikProps<unknown>).setFieldError("phoneNumber", errors[0].phone_number);
        }
      }

      handleFormCondtion=(condition:RequestType,values:userProfileFormDataType | {full_name : string} | {photo : string | Blob} )=>{
        let formData;
        if(condition === "profileDetails"){
          formData =  this.handleFormData(values as userProfileFormDataType);
        }else if(condition === "profilePicture"){
          formData = new FormData();
          formData.append('[data][attributes][photo]',(values as {photo : string | Blob}).photo);
        }else{
          formData = new FormData();
          formData.append('[data][attributes][full_name]',this.removeExtraSpaces((values as {full_name : string}).full_name.trim()));
        }

        return formData;
      }

      
    // Customizable Area End


    handleProfileDataCallerFunction=async()=>{
      const role = await getStorageData('role');
      if(role === 'user'){
        this.getUserProfileDetails();
      }else{
        this.getJobTitleForSuperadmin();
        this.isNewAdminCreateRoute();
      }
    }

    isNewAdminCreateRoute=()=>{
      const queryParams = new URLSearchParams(window.location.search);
      const adminId = queryParams.get('type') as string;
      if(adminId === 'create'){
        this.setState({isCreateNewAdmin:true,isEditAdminModeOpen:true});
      }else{
        this.getAdminProfileDetails();
        this.setState({isAdminProfileUpdateRoute:this.handleIsAdminProfile()})
      }
    }

    getJobTitleForSuperadmin=async()=>{
      const headers = {
        token : await getStorageData('token')
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
       this.getJobTitleForSuperAdminApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAllJobTItleEndPoint
      );

       requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    updateAdminPassword=async(values:password_initial_values_type)=>{
      const headers = {
        token : await getStorageData('token'),
        'Content-type':configJSON.contentTypeApiGetUserProfile
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updatePasswordProfileApiId = requestMessage.messageId;
      let passwords = this.handleDataforPasswords(values);
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updatePasswordEndpoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateUserApiType
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(passwords) 
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleDataforPasswords=(values:password_initial_values_type)=>{
      const {old_password,new_password,confirm_password} = values;
      return {
        "data": {
            "attributes": {
              current_password:old_password,
              new_password,
              password_confirmation:confirm_password
            }
        }
     }
    }

    handleIsAdminProfile=()=>{
      const queryParams = new URLSearchParams(window.location.search);
      const adminId = queryParams.get('id') as string; 
      return adminId;
    }

    isAdminProfileBoolean=()=>{
      return this.handleIsAdminProfile() === null;
    }

    handleImageBlob=(image:string | Blob|undefined)=>{
      let imageSrc;
      if (image instanceof Blob) {
      imageSrc = URL.createObjectURL(image);
      } else {
          imageSrc = image;
      }
      return imageSrc;

      
    }

    getAdminProfileDetails=async()=>{
      const adminID = this.handleIsAdminProfile();
      const endPoint =  adminID ? adminID : 'get_profile'
      const headers = {
        token : await getStorageData('token')
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
        
      this.getAdminProfileCallApiId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAdminProfileEndPoint + endPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    updateAdminProfileDetails=async(values:AdminProfileDataType)=>{
      const {isCreateNewAdmin} = this.state;
      const endPoint = isCreateNewAdmin ? configJSON.getAdminProfileEndPoint : configJSON.updateAdminProfileEndPoint;
      const method = isCreateNewAdmin ? configJSON.callTypeApiValidateMobileNo : configJSON.apiUpdateUserType;
      const headers = {
        token : await getStorageData('token')
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateAdminProfileApiId = requestMessage.messageId;
      let formData = this.handleAdminData(values);
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

      deleteAdminProfilePicture=async()=>{
      const {adminData:{photo,account_id}} = this.state;
      this.adminFormik?.setFieldValue("photo",null);
      if(!photo){
        return;
      }
      const headers = {
        token : await getStorageData('token')
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteAdminProfilePictureApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.removeAdminProfilephotostartPoint + '/' + account_id + configJSON.removeAdminProfilePhotoEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteProfieApiType
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    deleteAdminAccount=async()=>{
      const {adminData:{account_id}} = this.state;
      const formData = new FormData();
      formData.append('id',account_id as string);
      const headers = {
        token : await getStorageData('token')
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteAdminProfileApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteProfileEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteProfieApiType
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    handleAdminData = (values:AdminProfileDataType)=>{
        const {isCreateNewAdmin} = this.state;
        const {full_name,commission,account_type,photo,job_id,account_id} = values;
        const formData = new FormData();
        formData.append('id',account_id as string);
        formData.append("data[profile_attributes][][full_name]",full_name as string);
        formData.append("data[profile_attributes][][commission]",commission as string);
        formData.append("data[profile_attributes][][account_type]",account_type as string);
        job_id !== 'none' && formData.append('data[profile_attributes][][job_id]',job_id as string);
        isCreateNewAdmin && formData.append('data[email]',values.email as string);
        typeof photo === 'object' && photo !== null && formData.append("data[profile_attributes][][photo]",photo);
        return formData;
    }


  
    getUserProfileDetails=async()=>{

      const headers = {
        token : await getStorageData('token')
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
        
      this.getUserProfileCallApiId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getUserProfileEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleLoggedInUserRole=async()=>{
      const role = await getStorageData('role');
      this.setState({userRole:role});
    }

    updateUserProfileDetails=async(values:userProfileFormDataType | {full_name : string} | {photo : string | Blob},apiFor:RequestType)=>{
        
      this.setState({isLoading:true})

        const headers = {
          token : await getStorageData('token')
        }
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.updateUserProfileApiId = requestMessage.messageId;
        let formData = this.handleFormCondtion(apiFor,values);
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.updateUserProfileEndPoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.updateUserApiType
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.handleProfilePictureModalClose(false);
        this.handleEditFullNameModalClose(false);
    }

    removeExtraSpaces=(strChar:string = '')=> {
      return strChar.replace(/\s+/g, ' ').trim();
  }


    deleteUserProfilePicture=async()=>{
      if(!this.state.photo){
        return;
      }
      const headers = {
        token : await getStorageData('token')
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.deleteUserProfileApiId = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.removeProfilePhotoEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteProfieApiType
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    conditionHandler=(condition:boolean,value1:string,value2:string)=>{
      return condition ? value1 : value2
    }

    updateResetPwHandler=(isMyToken:boolean)=>{
      if(isMyToken) this.handleChangePasswordModalOpen(true);
      else this.resetAdminPw()
    }

    resetAdminPw = async() => {
      const {account_id} = this.state.adminData;
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token" : await getStorageData('token')
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.resetAdminPwApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.resetAdminPasswordApiEndpoint + `?id=${account_id}`
      );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleResetAdminPwResponse=(responseJson:ResetMessage)=>{
      const {data:{message}} = responseJson;
      this.handleTriggerAlertMessage(message);
    }

    handleupdateAnotherEmailFailRes=(error:string[])=>{
      (this.emailFormik as FormikProps<unknown>).setFieldError('email',error[0])
    }

    handleUpdateAnotherEmailRes=(responseJson:MetaMessage)=>{
      const {meta : {message}} = responseJson;
      const {changeEmail} = this.state;
      this.setState({isEditEmailModalOpen:false});
      this.handleTriggerAlertMessage(message);
      this.setState({adminData:{...this.state.adminData,email:changeEmail}})
    }

    handleSubheading = ()=>{
      const {userRole,isAdminProfileUpdateRoute} = this.state;
      if(userRole === "user"){
        return "Edit Profile"
      }
      else{
       return  isAdminProfileUpdateRoute ? 'Edit Admin Information' : 'Edit Personal Information'
      }
    }

    getAirports = (value:string) => {
   
      const header = {
        "Content-Type": configJSON.advancedsearchApiContentType,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.getAirportsAPICallId = requestMessage.messageId;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.airportsAPIEdnpoint}=${value}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleAPICallForAirports = (changedValue:string)=>{
      if(changedValue.length > 2){
        this.getAirports(changedValue)
      }else{
        this.setState({searchedAirports:[]});
      }
    }

    handleAirportSearch = (value: string) => {
      clearTimeout(this.delayTimer)
      this.delayTimer = setTimeout(() => {
          clearTimeout(this.delayTimer);
          this.handleAPICallForAirports(value)
      }, 500);
    }

    handleAirportsResponse = (airports:AirportType[],isPopular:boolean)=>{
      let airportManipulatedData = airports.map((item:AirportType)=>{
        return {name:item.name,city:item.city,countryCode:item.country.code,code:item.icao,id :item.id}
      })
    
      this.setState({searchedAirports:airportManipulatedData})
    }

    getOptionLabel = (option:{name:string|null,city:string|null,code:string|null})=>{

      if(option.city){
        return `${option.code} - ${option.name}, ${option.city}`
      }
      else{
        return option.name
      }
    
    }

    handleCardProvider = (card_type:string)=>{
      switch (card_type) {
        case 'MasterCard':
            return masterCard;
        case 'Visa':
            return visa;
        case 'Amex':
            return americanExpress;
        default:
            return defaultCard;
    }
    
    }

    getCountryFlag = (countryCode: string) => {
      return countryCode
        .toUpperCase()
        .replace(/./g, (char: string) => String.fromCodePoint(127397 + char.charCodeAt(0)))
    }
    

// Customizable Area End

}
