import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { DebitSkyCardIcon, DebitSkyCardSmallerIcon, SkyCardDebitCardIcon, SkyCardIcon} from "../../../components/src/assets";
import { AddPaymentMethodModal } from "../../../components/src/ReUsableModal";
import { ButtonBox,PaymentCard, DFlexBetween, DFlexAlignCenter, GoldishSimpleButton,StyledBoxCfP, MuiModal, StatusModal, MuiResuableStatusModalBody, FormSubmitButton, SurityModal, SureModalButtonContainer, TopupBox, CustomFormTextField, Footer, handleVendorChargedChange, dollarTextFieldInputProps, formatNumberWithCommas,} from "../../../components/src/MuiCustomComponents";
import {AirplaneSvg,deleteIcon, edit_icon} from "./assets";
import { Formik, FormikProps, FormikValues } from "formik";
import { topupAmountSchema } from "../../../components/src/Validations";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const skyCards = [
  {
    type:"Debit",
    remaining:"$12,300",
  },
  {
    type:"25 Hour",
    remaining:"12Hrs & 32Mins",
    aircraft:"Super Midsize Jet",
  }
]

const debitCards = [
  {
    type:"debit",
    cardholder_name:"Leslie Alexander",
    card_number:"5678"
  },
  {
    type:"Visa",
    cardholder_name:"Leslie Alexander",
    card_number:"5678"
  }
]

// Customizable Area End

import Cfpaymentgateway33Controller, {
  Card25hrType,
  CardDetails,
  DebitFormInitValueType,
  Props,
  configJSON,
} from "./Cfpaymentgateway33Controller";


export default class Cfpaymentgateway33 extends Cfpaymentgateway33Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPaymentMethods = ()=>{
    const {showPaymentModal,action,focusedLabel,cardIndex} = this.state
    return(
      <>
      <Box className="booking_section" style={{rowGap:"1.5rem"}}>
        {this.renderHeading("Payment Methods")}
        <Box className="profile_main_container" mb={0} pt={"1.5rem"}>
          {this.renderCardsWithTitle("Sky Cards")}
        </Box>
        <Box className="profile_main_container" mb={0} pt={"1.5rem"}>
          {this.renderCardsWithTitle("Debit/Credit Cards")}
          <ButtonBox mt={"2.2rem"} onClick={()=>this.openPaymentModal("add",-1)} id="add_debit_card">
            + Add New Credit/Debit Card
          </ButtonBox>
        </Box>
      </Box>
      {showPaymentModal &&(
        <AddPaymentMethodModal
        showModal = {showPaymentModal}
        actionType={action}
        openPaymentModal = {this.openPaymentModal}
        cancel={this.closePaymentModal}
        focusedField={focusedLabel}
        focusField={this.handleFocus}
        {...(action !== "add" && cardIndex >= 0 ?{initialValues:this.getCardDetails()}:{})}
        deleteCard={()=>{this.handleSureModalOpen(true,cardIndex)}}
        isDeactivated={false}
        createCard={this.handleCreateNewCard}
        cardFormik={(formikProps: FormikProps<FormikValues>) => this.cardFormik = formikProps}
        makePrimaryPaymentMethod= {this.handleMakePrimaryMethod}
        />
      )}
      {this.renderPaymentUpdateModal()}
      {this.renderDeleteCardConfirmationModal()}
      </>
    )
  }

  renderSkyCards = ()=>{
    const title = this.getTitle()
   return <>
    <Box className="booking_section" style={{rowGap:"1.5rem"}}>
      {this.renderHeading(title)}
      <Box className="profile_main_container" mb={0}>
      {this.renderCards()}
      </Box>
    </Box>
    {this.renderFooter()}
    </>
  }

  renderHeading = (heading:string)=>{
    return(
      <GoldishSimpleButton style={{ color: 'white', textTransform: "capitalize", justifyContent:'start',fontSize:'1.6rem'}}>{heading}</GoldishSimpleButton>
    )
  }

  renderCardsWithTitle = (title:string)=>{
    return(
      <>
      {this.renderSubHeading(title)}
      {title==="Sky Cards"?this.renderCards():this.renderDebitCards()}
                        
      </>
    )
    
  }

  renderSubHeading = (heading:string)=>{
    return(
      <Box style={{marginBottom:"1.5rem", color: 'white', textTransform: "capitalize", justifyContent:'start',fontSize:"1.5rem", fontWeight:600}}>{heading}</Box>

    )
  }

  renderCards = () => {
    const { cardType } = this.state;
    const isSkyCardPage = cardType;
  
    return (
      <Grid container spacing={3}>
        {isSkyCardPage ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4} xl={4}>
              <Grid container direction="column" spacing={3}>
                {[isSkyCardPage === "debit" ? skyCards[0]:skyCards[1]].map((card) => (
                  <Grid item>
                    {this.renderSkyCard(card)}
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={8} xl={8}>
              {this.renderTopups()}
            </Grid>
          </Grid>
        ) : (
          skyCards.map((card) => (
            <Grid item sm={12} md={6} lg={4} xl={4}>
              {this.renderSkyCard(card)}
            </Grid>
          ))
        )}
      </Grid>
    );
  };
    
  renderTopups = ()=>{
    const {cardType,card25HrData,currentTopups} = this.state
    return <Grid item xs={12}>
      <TopupBox>
        <Box className="topup-heading">{cardType === "debit" ? "Add Balance to my Debit Sky Card":"Choose your Aircraft Type"}</Box>
        <Box className="topup-subheading">{cardType === "debit" ? "Choose from predefined amount or add a custom value.":"Purchase 25 hours of flight on aircrafts listed below,for a fixed price."}</Box>
        {cardType === "debit" && <Box className="topupBox">
          {this.renderCustomAmountBox()}
          {currentTopups.map((topup,index)=>this.renderTopupBox(topup,index))}
        </Box>}
        {cardType === "25hr" && <Box display={"flex"} flexDirection={"column"} gridRowGap={"1.5rem"} pt={1}>{(card25HrData as Card25hrType[]).map((card:Card25hrType,index)=>this.renderCustomAircraft(card,index))}</Box>}
        </TopupBox>      
    </Grid>
  }

  renderTopupBox = (topup:DebitFormInitValueType,index:number)=>{
    return <Box className="topup">
      <Box className="amount">{`$ ${topup.top_up}`}</Box>
      <Button className="buy-btn" 
      style={{border:topup.isSelected?"1px solid #F87171":"1px solid #FFD27E",color:topup.isSelected?"#F87171":"#FFD27E"}} 
      onClick={()=>this.handleBuyTopup(index)}>{topup.isSelected ? "Remove" : "Buy Now"}</Button>
    </Box>
  }

  renderCustomAmountBox = ()=>{
    const {isCustomAmountAdded} = this.state
    return <Formik
      initialValues={{amount:""}}
      validationSchema={topupAmountSchema}
      validateOnChange
      validateOnBlur
      onSubmit={(values,{setFieldValue})=>{
        this.handleCustomAmount(values.amount,setFieldValue)
      }}>
        {({values,setFieldValue,handleSubmit})=>{
          return <Box className="custom-amount topup">
          <CustomFormTextField
            autoComplete="off"
            placeholder="Enter custom amount"
            size="medium"
            fullWidth
            isFocusedTypeInput = {true}
            initialBorderColor='#636F73'
            variant={"outlined"}
            id="amount"
            value={values.amount}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              !isCustomAmountAdded && handleVendorChargedChange(event,setFieldValue,"amount")
            }}  
            // InputProps = {
            //   dollarTextFieldInputProps
            // }
            style={{maxWidth:"82%"}}
            height = {"3.5rem"}
            client={true}
        />
          <Button 
          className="buy-btn" 
          style={{border:isCustomAmountAdded?"1px solid #F87171":"1px solid #FFD27E",color:isCustomAmountAdded?"#F87171":"#FFD27E"}} 
          onClick={()=>handleSubmit()}>{isCustomAmountAdded ?  "Remove" : "Buy Now"}</Button>
        </Box>
        }}
    </Formik>
  
  }

  renderCustomAircraft = (card:Card25hrType,index:number)=>{
    const {isSelected,attributes:{aircraft_category,name,price}} = card
    return <Box className="aircraft-container">
      <Box className="aircraft" style={{maxWidth:"85%"}}>
        <Box className="category">{aircraft_category}</Box>
        <Box display={"flex"} alignItems={"center"} gridColumnGap={"1rem"}>
          <Box component={"span"} className="aircraft-name">{name}</Box>
          <Box component={"span"} className="price">({`${price} - Plus Tax`})</Box>
        </Box>
      </Box>
      <Box display={"flex"} alignItems={"center"}>
        <Button style={{ border: isSelected ? "1px solid #F87171" : "1px solid #FFD27E", color: isSelected ? "#F87171" : "#FFD27E" }}
          className="buy-btn"
          onClick={() => this.handleAircraftTopups(index)}>
          {isSelected ? "Remove" : "Buy Now"}
        </Button>
      </Box>
    </Box>
  }
  renderSkyCard = (card:any)=>{
    const isDebit = card.type === "Debit"
    const cardIconContainerStyle = isDebit ? "card_img debitskyCard":"sky_card card_img"
    return(
      <Grid item xs={12}>
        <StyledBoxCfP>
          <Grid container className="card_parent">
            <Grid item xs={8} className="card_content">
              {isDebit ?this.renderDebitCardContent(card):this.renderSkyCardContent(card)}
            </Grid>
            <Grid item xs={4} className={cardIconContainerStyle}>{isDebit?<DebitSkyCardIcon/>:<SkyCardIcon/>}</Grid>
          </Grid>
        </StyledBoxCfP>        
      </Grid>      
    )
  }

  renderSkyCardContent = (card:any)=>{
    const {cardType}= this.state
    const {type,aircraft,remaining} = card
    return <Box className="card_container">
      <Box className="view_button_parent" gridColumnGap={1}>
        <Box><SkyCardDebitCardIcon /></Box>
        <Box className="card_heading" color={"#fff"}>{type} Sky Card</Box>
      </Box>
      {!cardType && <Box className="booking_section" style={{color:"#fff",rowGap: "0.1rem" }}>
        <Box display={"flex"} justifyContent={"center"} gridColumnGap={"0.5rem"} mb={0.5}>
        <Box display={"flex"} alignItems={"center"}><AirplaneSvg/></Box>
        <Box className="card_name">{aircraft}</Box>
        </Box>
        <Box fontSize={"1rem"} color={"#cbd5e1"}>Remaining</Box>
        <Box fontSize={"1.4rem"}>{remaining}</Box>
      </Box>}
    </Box>
  }

  renderDebitCardContent=(card:any)=>{
    const {cardType,topupBalance} = this.state
    const {type,remaining} = card
    return <Box className="card_container">
      <Box className="view_button_parent" style={{ columnGap: '1rem' }}>
        <Box style={{ display: "flex", gap: "6px" }}>
          <DebitSkyCardSmallerIcon />
          <Box className="card_heading" color={"#fff"} style={{ alignSelf: "center" }}>{type} Sky Card</Box>
        </Box>
        <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Box className="view_debit_card" component={'span'} style={{ marginTop: '-4px', color: "#FFF" }}>Remaining</Box>
          <Box fontSize={"1.4rem"} style={{ marginTop: '-4px', color: "#FFF" }}>
            {cardType === "/SkyCards" ? topupBalance : remaining}.00
          </Box>
        </Box>
      </Box>
    </Box>
  }

  renderDebitCards = ()=>{
    const {cards} = this.state
    return<Grid container spacing={2}>
      {cards.map((card,index)=>this.renderDebitCard(card,index))}
    </Grid>
  }

  renderDebitCard = (card:CardDetails,index:number)=>{
    const {cards:{card_type,card_number,card_holder_name},primary_method} = card.attributes
    return <Grid item sm={12} md={6} lg={4} xl={3}>
      <PaymentCard onClick={()=>this.openPaymentModal("show",index)} id="debit_card"> 
          <DFlexBetween>
          <div className="card-btns">
          <div className="div-img"><img  src={this.handleCardProvider(card_type)} alt="" height={"28px"} width={"44px"} /></div>
          {primary_method && <button className="card-btn">Primary</button>}
          </div>
          <DFlexAlignCenter>
          <>
              <Box px={"0.5rem"} id="delete_icon" onClick={(event: React.MouseEvent<HTMLImageElement>) => {
                  event.stopPropagation()
                  this.handleSureModalOpen(true, index)
                }}>
                <img src={deleteIcon} alt="" className="pointer delCardModal"/>
              </Box>
              <Box px={"0.5rem"} id="edit_icon" onClick={(event: React.MouseEvent<HTMLImageElement>) => {
                  event.stopPropagation()
                  this.openPaymentModal("edit", index)
                }}>
                <img src={edit_icon} alt="" className="pointer"/>
              </Box>
            </>                                 
          </DFlexAlignCenter>
          </DFlexBetween>                    
          <Box className="card-name">{card_holder_name}</Box>
          <p className="card-info"> **** **** **** {card_number}</p>
        </PaymentCard>
    </Grid>
  } 
  
  renderPaymentUpdateModal = ()=>{
    const {action} = this.state
    const isEditAction = action === "edit"
    const subHeading = isEditAction? "You can access the revised payment method at checkout": "You can now access your payment method at checkout"
      return <MuiModal
      open={this.state.isStatusModalVisible}
    >
      <StatusModal className="status_update_modal" payment={true}>
      <MuiResuableStatusModalBody
        heading = {`Payment Method ${isEditAction ? "Edited":"Added"} Successfully`}
        subHeading = {subHeading}
      >
        <FormSubmitButton onClick={()=>this.handleStatusVisible(false)} fullWidth className="okay_button">Back to Payments</FormSubmitButton>
      </MuiResuableStatusModalBody>
      </StatusModal>
    </MuiModal>

  }

  renderDeleteCardConfirmationModal=()=>{
    const {isSureModalOpen,cardIndex} = this.state
  return <SurityModal isOpen={isSureModalOpen}><SureModalButtonContainer submit={this.handleDeleteCard} cancel={()=>this.handleSureModalOpen(false,cardIndex)}/></SurityModal>
  }

  renderFooter = ()=>{
    const {totalAmount} = this.state
    return <Footer>
      <Box className="profile_submission">
        <Box display={"flex"} gridColumnGap={"3rem"} alignItems={"center"} width={"100%"}>
          <Box>
            <Box color={"#94a3b8"} fontSize={"1.2rem"}>Amount Selected</Box>
            <Box color={"#fff"} fontSize={"1.4rem"} fontWeight={700}>$ {formatNumberWithCommas(String(totalAmount))}.00</Box>
          </Box>
          <FormSubmitButton style={{ color: totalAmount ? "black" : "white", background: totalAmount ? "#FFD17A" : "#3E4247", padding: "2rem 5rem" }}>Checkout</FormSubmitButton>
        </Box>
      </Box>
    </Footer>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      this.state.cardType ? this.renderSkyCards() : this.renderPaymentMethods()
      // this.renderPaymentMethods()
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },

};
// Customizable Area End
