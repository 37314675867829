Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfavinodeintegration2";
exports.labelBodyText = "cfavinodeintegration2 Body";

exports.btnExampleTitle = "CLICK ME";


exports.getAvailableFlightDataEndPoint = "bx_block_cfavinodeintegration2/aircrafts/available_aircrafts";
exports.postNewBookingEndPoint = "bx_block_admin/orders";
exports.getEachAircraftDetailsEndPoint = 'bx_block_catalogue/aircraft_catalogues';
// Customizable Area End