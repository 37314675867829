import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { allFilter } from "./assets";
import {getStorageData} from "../../../framework/src/Utilities"

export interface UpcomingBookingAttributes {
  id: number;
  order_id: number;
  client: string;
  booking_no: string;
  time_to_first_leg: string;
  number_of_legs: number;
  coming_in: string;
  departing_from: string;
  departure_date: string;
  additional_details: boolean;
  booking_date: string;
  status: string;
  vendor: {
    name: string;
  };
  vendor_status: string;
}

export interface UpcomingBookingData {
  id: string;
  type: string;
  attributes: UpcomingBookingAttributes
}

interface UpcomingBookingResponse {
  errors?: string[];
  total_pages: number;
  data:{
    data: UpcomingBookingData[];
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchKey: string;
  anchorEl: HTMLElement | null;
  appliedFilter: string[];
  sortDesc: boolean;
  applySorting: string;
  isLoading: boolean;
  page: number;
  perPage: number;
  totalPages: number;
  upcomingBookings: UpcomingBookingData[];
  isUpcomingBooking: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ClientUpcomingBookingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUpcomingOrderApiCallId: string = "";
  private searchTimeout: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      searchKey: "",
      anchorEl: null,
      appliedFilter: allFilter,
      sortDesc: false,
      applySorting: 'departure_date',
      isLoading: false,
      page: 1,
      perPage: 10,
      totalPages: 0,
      upcomingBookings: [],
      isUpcomingBooking: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getUpcomingOrderApiCallId) {
        this.handleUpcomingOrdersResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): Promise<void> {
    this.getUpcomingOrders();
    this.getCurrentPathValue();
    return Promise.resolve();
  }

  getUpcomingOrders = async () => {
    const { page, perPage, searchKey, applySorting, sortDesc, appliedFilter } = this.state;
    const headers = {
      'token': await getStorageData('token')
    };
    const applyDescendingSort = sortDesc ? "&sort_type=desc" : ""
    const type = this.getCurrentPathValue() ? "upcoming_bookings" : "previous_bookings"

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
 
    this.getUpcomingOrderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.clientUpcomingBookings}?type=${type}&page_number=${page}&number_of_items_per_page=${perPage}&query=${searchKey}&sort=${applySorting}${applyDescendingSort}&${this.getFilterString(appliedFilter)}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
 
    this.setState({
      isLoading: true,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleUpcomingOrdersResponse = (responseJson : UpcomingBookingResponse) => {
    if(responseJson.data){
      const { data : {data} , total_pages } = responseJson;
      this.setState({ upcomingBookings: data, isLoading: false, totalPages: total_pages })
    }
  }

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
  
    this.setState({ searchKey: value, page: 1 });

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.getUpcomingOrders();
    }, 1500);
  };

  pageHeading = () => {
    const {location:{pathname}} = window;
    switch (pathname) {
      case '/ClientOrderDetailsView':
        return 'Upcoming Bookings';
      case '/ClientPreviousBookings':
        return 'Previous Bookings';
      default:
        return 'Previous Bookings';
    }
  }

  getCurrentPathValue = () => {
    const { location:{pathname}} = window;
    if(pathname === '/ClientOrderDetailsView'){
      this.setState({ isUpcomingBooking: true })
      return true;
    }
    else if(pathname === '/ClientPreviousBookings'){
      this.setState({ isUpcomingBooking: false })
      return false;
    }
  }

  handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget })
  };

  handleClose = (event: React.MouseEvent<HTMLElement>) => {
    if ((event.target as HTMLInputElement).tagName !== 'INPUT' || (event.target as HTMLInputElement).type !== 'checkbox') {
      this.setState({ anchorEl: null })
    }
  };

  handleCheckboxChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    event.stopPropagation();
    const { appliedFilter } = this.state;
    if (appliedFilter.includes(value)) {
      this.setState({ appliedFilter:  appliedFilter.filter((filter: string) => filter !== value) })
    } else {
      this.setState({ appliedFilter:  [...appliedFilter, value] })
    }
  };

  handleSorting = (value: string) => {
    const{ sortDesc, applySorting } = this.state
    if(value === applySorting){
      this.setState({ sortDesc: !sortDesc})
    }
    else{
      sortDesc &&  this.setState({ sortDesc: !sortDesc})
    }
    this.setState({ applySorting: value, page: 1 },() => {this.getUpcomingOrders()})
  }

  handleCurrentPage = (page: number) => {
    this.setState({ page: page },() => {this.getUpcomingOrders()})
  }

  handleApplyFilter = () => {
    this.setState({ page: 1, anchorEl: null }, () => this.getUpcomingOrders());
  }

  getFilterString = (filters: string[]): string => {
    let filterQuery = filters.map(filter => `filter[]=${encodeURIComponent(filter)}`).join('&');
    return filterQuery;
  };  
  // Customizable Area End
}
