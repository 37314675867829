import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react"
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment from "moment";
import { handleNavigation, handleTriggerAlertMessage } from "../../../components/src/Navigator";
import {getStorageData} from "../../../framework/src/Utilities";

const origin = 'Desktop'


interface Province {
  code: string
  name: string
}

interface StartPositionType {
  id: string
  href: string
  type: string
  name: string
  city: string
  country: Province
  unofficialICAO?: string
  faa: string
  province: Province
  icao?: string
  iata?: string
}

interface ArrivalDepartureDateTimeType {
  dateTimeUTC: string
  dateTimeLocal: string
  calculated: boolean
}

interface StarEndAirportType {
  id: string
  href: string
  type: string
  name: string
  city: string
  country: Province
  icao: string
  iata: string
  faa: string
  province: Province
}

export interface SegmentType {
  startAirport: StarEndAirportType
  endAirport: StarEndAirportType
  departureDateTime: ArrivalDepartureDateTimeType
  arrivalDateTime: ArrivalDepartureDateTimeType
  timeTBD: boolean
  paxCount: number
  paxSegment: boolean
  blockMinutes: number
  flightMinutes: number
  fuelMinutes: number
  distanceNM: number
  fuelStopCount: number
}

interface Lift {
  id: string
  aircraftCategory: string
  aircraftType: string
  aircraftTail: string
  maxPax: number
  ownAircraft: boolean
  image: null | string,
  aircraft_id: null | string
}

interface SellerPriceWithoutCommission {
  currency: string
  price: number
  conversionCurrency: string
  convertedPrice: number
  priceAsString: string
}

interface DetailedAddress {
  street: string
  postBox: string
  postCode: string
  city: string
  country: Province
  province: string
}

interface ContactInfo {
  emails: string[]
  phone: string
  mobilePhone: string
  url: string
  office: string
  address: string
  detailedAddress: DetailedAddress
}

interface SellerCompany {
  id: string
  displayName: string
  contactInfo: ContactInfo
  icaoDesignator?: string
}

interface Quote {
  sellerCompany: SellerCompany
  sellerPrice: SellerPriceWithoutCommission
  sellerPriceWithoutCommission: SellerPriceWithoutCommission
  avinodeGenerated: boolean
  reservable: boolean
  lift: Lift
  segments: SegmentType[]
  price: number
}

interface Aircraft {
  id: string
  quote: Quote
  startPosition: StartPositionType
  initialPositioningMinutes: number
}


interface CategorizedAircraft {
  index: number
  category: string
  asearch_id:string
  price_starts_from: number
  min_passenger_capacity: number
  max_passenger_capacity: number
  available_no_of_stops: number[]
  aircrafts: Aircraft[]
  aircrafts_count: number
  paginated_aircrafts_count: number
  current_page: number
  total_pages: number
}

interface Filters {
  no_of_stops: string[]
  selected_categories: string[]
}


interface FromToAirportType {
  id: string
  href: string
  type: string
  name: string
  city: string
  country: Province
  icao: string
  iata: string
  faa: string
  province: Province
}

interface RequestSummary {
  from_airport: FromToAirportType
  to_airport: FromToAirportType
  departure_date: string
  departure_time: string
  passenger_configuration: string
}

export interface AvailableBookingData {
  category: string
  request_summary: RequestSummary[]
  sort: Sort
  filters: Filters
  categorized_aircrafts: CategorizedAircraft[]
}

interface Sort {
  price: number
}

interface OrderCreateResType{
  order : {
    meta : {
      message : string
    },
    data : {
      id : string
    }
  }
}

interface FormDataType{
  data : {
    trip_type : string,
    category : string,
    legs : {attributes : {passenger_configuration : string}}[] 
  }
} 

type FormHisType = TripTypeFont & {trips : TripTypeFont[]}

interface TripTypeFont{
  departure : string, 
  passengers : number,
  fromLocation : {id:string},
  toLocation : {id : string} ,
  leg1Departure: string,
  leg1Passengers:number,
  aircraftCategory:string
}

export type ExpandRowsType = {} | Record<string,string>

interface CatalogueAircarft {
  type: string
  id: string
  attributes: {
    aircraft_number: string
    name: string
    manufacturer: string
    category: string
    passengers: number
    luggage_capacity: string
    year_of_manufacture: string
    year_of_refurb: string
    created_at: string
    main_image: FileCatType
    aircraft_images: {
      files: FileCatType[]
    }
  }
}

interface FileCatType{
  id: number
  file_name: string
  file_url: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history:any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedFilter : number;
  isExpanded:boolean;
  expandedRows:{} | Record<string,string>;
  availableFlightData: AvailableBookingData | [];
  loader:boolean;
  formType:null | number;
  isFilterModalVisible:boolean,
  aircraftsFilterOption:[] | string[];
  numberOfStopsFilterOption:[] | string[];
  allFlightData:[] | AvailableBookingData;
  numberOfFlights:number,
  pageNumber:number,
  formData:{} | FormDataType,
  isMoreLoading:boolean,
  loadMoreIndex:null | number,
  pageStore:number[] | null,
  loadMoreText:string
  isViewFlightDetailsOpen:boolean
  isInfoToggled:boolean
  aircarftCatalogue:Record<string,CatalogueAircarft> 
  activeCatalogueId:null | string
  addInfoObject:{
    index1:null | number
    index2:null | number
    header_name:string
  }
  isLoadingAircarftInfo:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfavinodeintegration2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAvailableFlightCallApiId:string = ''
  postNewBookingCallApiId:string = '';
  inputFileRef = React.createRef()
  getAircraftCatApiCallId:string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedFilter : 0,
      isExpanded:false,
      expandedRows:{},
      availableFlightData:[],
      loader:true,
      formType:null,
      isFilterModalVisible:false,
      aircraftsFilterOption: [],
      allFlightData:[],
      numberOfStopsFilterOption:[],
      numberOfFlights:10,
      pageNumber:1,
      formData:{},
      isMoreLoading:false,
      loadMoreIndex:null,
      pageStore:null,
      loadMoreText:'searching for flights',
      isViewFlightDetailsOpen:false,
      isInfoToggled:true,
      aircarftCatalogue:{},
      activeCatalogueId:null,
      addInfoObject:{
        index1:null,
        index2:null,
        header_name:''
      },
      isLoadingAircarftInfo:true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      ); 
      if(responseJson){
        if(apiRequestCallId === this.getAvailableFlightCallApiId){
            this.handleAircraftResponse(responseJson);
          }
        
        }
      if(apiRequestCallId === this.getAircraftCatApiCallId){
        this.handleCatalogueFlightRes(responseJson);
      }

      if(apiRequestCallId === this.postNewBookingCallApiId){
        this.handleSuccessCreateBookingResponse(responseJson)
        this.setState({loader:false})
    }}


    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount(){
      this.handleDataFromAdvanceSearch();
  }


  // Handler Functions
  formToggler=(toggleTo: 0 | 1 | 2)=>{
    this.setState({selectedFilter:toggleTo});
  }

  flightToggler = (parentIndex: number) => {
    this.setState((prevState) => ({
      expandedRows: {
        ...prevState.expandedRows,
        [parentIndex]: !(prevState.expandedRows as unknown as Record<string,string>)[parentIndex as unknown as string],
      },
    }));
  };


  handleDataFromAdvanceSearch=()=>{
    const {history} = this.props;
    const {location: {state : {navigationBarTitleText: {formData : {formType, data}}} }} = history;
    const formData = this.handleCorrectFormatDataProvider(data,formType);
    this.handleFormType(formType);
    this.setState({formData:formData});
    this.getAircrafts(formData);
  }

  handleFormType=(form_type:null | number)=>{
    this.setState({formType:form_type})
  }

  handleEdit=()=>{
    const {history} = this.props;
    const {location: {state : {navigationBarTitleText: {formData}} }} = history;
    this.handleNavigationtoAdvanceSearchWithData(formData);  
  }

  handleNavigationtoAdvanceSearchWithData = (formData:FormHisType) => {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(getName(MessageEnum.NavigationTargetMessage), 'AdvancedSearch');
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), {formData});
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
    }

  handleCorrectFormatDataProvider=(data:FormHisType,formType:number)=>{
    const {aircraftCategory} = data;
    const formData = {
      data:{
        trip_type: this.handleTripType(formType),
        category: aircraftCategory.toLowerCase(),
        legs: this.handleLegs(data,formType)
      },
      admin:true
    }
    return formData;
  }

  handleTripType=(formType:number)=>{
    let form_type;
    if(formType === 0){
      form_type = 'one_way';
    }else if(formType === 1){
      form_type = 'round_trip';
    }else{
      form_type = 'multi_leg'
    }
    return form_type;
  }

  handleLegs=(data:FormHisType, formType : number)=>{
    let legs;
      if(formType === 0){
        const {departure, passengers, fromLocation, toLocation } = data;
        legs = [this.handleEachLegData(departure,passengers,fromLocation.id,toLocation.id)]
    }else if(formType === 1){
      const {departure, passengers, fromLocation, toLocation, leg1Departure , leg1Passengers } = data;
      legs = [this.handleEachLegData(leg1Departure,leg1Passengers,fromLocation.id,toLocation.id),
        this.handleEachLegData(departure,passengers,toLocation.id,fromLocation.id)]
    }else if(formType === 2){
      const {trips} = data;
      legs = trips.map(({departure,passengers,fromLocation,toLocation})=>this.handleEachLegData(departure,passengers,fromLocation.id,toLocation.id))
    }
      return legs;
  }

  handleAircraftResponse=(responseJson:{data : AvailableBookingData} | {error : {messages : string[]}})=>{
     if((responseJson as {data : AvailableBookingData}).data){
      this.handleAircraftSuccessResponse(responseJson as {data : AvailableBookingData});
      return;
     }
     this.handleEdit();
     handleTriggerAlertMessage(this.send,'Date range is invalid!','error');
  }

  handleAircraftSuccessResponse=(responseJson:{data : AvailableBookingData})=>{
    const {loadMoreIndex} = this.state
    const {filters:{selected_categories, no_of_stops},categorized_aircrafts} = responseJson.data;
    if(loadMoreIndex === null){
      this.setState({availableFlightData:responseJson.data,allFlightData:responseJson.data,isMoreLoading:false,numberOfStopsFilterOption:no_of_stops,aircraftsFilterOption:selected_categories,pageStore : Array(categorized_aircrafts.length).fill(1)});
    }else{
      this.handleResponseAndFilterForLoadMore(responseJson);
    }
    this.handleLoader(false);
  }

  handleResponseAndFilterForLoadMore=(responseJson:{data : AvailableBookingData})=>{
    const {categorized_aircrafts} = responseJson.data;
    const {aircraftsFilterOption,numberOfStopsFilterOption,loadMoreIndex,allFlightData} = this.state;
    let allData = {...this.handleResponseDataManager(allFlightData as AvailableBookingData,loadMoreIndex as number,categorized_aircrafts)};
    this.setState({
      allFlightData:allData,
      isMoreLoading:false
    });

      
    this.handleFilter(aircraftsFilterOption,numberOfStopsFilterOption);
  }

  handleResponseDataManager=(data:AvailableBookingData,loadMoreIndex:number,categorized_aircrafts:AvailableBookingData['categorized_aircrafts'])=>{
    let allData = {...data};
    let allDataAircrafts = allData['categorized_aircrafts'][loadMoreIndex].aircrafts
    allData['categorized_aircrafts'][loadMoreIndex].aircrafts = [ ...allDataAircrafts,...categorized_aircrafts[loadMoreIndex].aircrafts]
    return allData;
  }




  handleLoader=(isLoading:boolean)=>{
    this.setState({loader:isLoading})
  }

  handleCheckAndUncheck = (isChecked:boolean,setFieldValue:(value1:string,value2:string[])=>void,option:string,value_selected_categories:string[],activeFilterKey:keyof Filters) => {
      const isAnyOption = option === "Any";
    let newSelected;

    if (isAnyOption) {
        newSelected = isChecked ? [] : [...(this.state.availableFlightData as AvailableBookingData).filters[activeFilterKey]];
    } else {
        if (isChecked) {
            newSelected = value_selected_categories.filter((item) => item !== option && item !== 'Any');
        } else {
            newSelected = [...new Set([...value_selected_categories, option])];
        }
        const allOptionsCount = (this.state.availableFlightData as AvailableBookingData).filters[activeFilterKey].length - 1;
        if (newSelected.length === allOptionsCount) {
            newSelected.push("Any");
        }
    }

    setFieldValue(activeFilterKey, newSelected);
  };

  handleEachLegData = (date:string,passengers:number,fromLocation:string,toLocation:string)=>{
    let eachLegData = {
      attributes : {
        "departure_date": moment(date).format('YYYY-MM-DD'),
        "departure_time": moment(date).format('HH:mm'),
        "passenger_configuration": String(passengers),
        "from": fromLocation,
        "to": toLocation
      }
    }
    return eachLegData
  }


  handleFilter(filterAircraftArray:string[], numberOfStopsFilterArray:string[]) {
    let filteredData = this.handleFilterByAircrafts(filterAircraftArray, this.state.allFlightData as AvailableBookingData);
    filteredData = this.handleFilterByStops(numberOfStopsFilterArray, filteredData);

    this.handleAfterFilter(filteredData, filterAircraftArray, numberOfStopsFilterArray);
    const appliedSorting = this.handleSorterKey();
    this.handleSorting(appliedSorting as unknown as "flightMinutes");
  }

  handleFilterByAircrafts = (filterAircraftArray:string[], allFlightDataInstance:AvailableBookingData) => {
    const lowercaseFilterAircraftArray = filterAircraftArray.map((category) => category.toLowerCase());
    return {
        ...allFlightDataInstance,
        categorized_aircrafts: allFlightDataInstance.categorized_aircrafts.filter((aircraft) =>
            lowercaseFilterAircraftArray.includes(aircraft.category.toLowerCase())
        )
    };
  }

  handleFilterByStops = (numberOfStopsFilterArray:string[], allFlightDataInstance:AvailableBookingData) => {
    const includeAny = numberOfStopsFilterArray.includes('Any');
    return {
        ...allFlightDataInstance,
        categorized_aircrafts: allFlightDataInstance.categorized_aircrafts.map((category) => ({
            ...category,
            aircrafts: category.aircrafts.filter((aircraft) =>
                includeAny || aircraft.quote.segments.every((segment) =>
                    numberOfStopsFilterArray.includes(segment.fuelStopCount as unknown as string)
                )
            )
        }))
    };
};


  handleSorterKey = ()=>{
    const {selectedFilter} = this.state;
    let sortedKey;
    if(selectedFilter === 0){
      sortedKey = 'price';
    }else if(selectedFilter === 1){
      sortedKey = 'flightMinutes';
    }else{
      sortedKey = 'fuelStopCount';
    }
    return sortedKey;
  }

  handleAfterFilter=(dataToShow:AvailableBookingData,filterArray:string[],numberOfStopsFilterArray:string[])=>{
    this.setState({availableFlightData:dataToShow,aircraftsFilterOption:filterArray,numberOfStopsFilterOption:numberOfStopsFilterArray});  
    this.handleFilterModalOpenAndClose(false);
  }

  handleSorting = (sortOption : 'flightMinutes' | 'fuelStopCount' | 'price') => {
    const { availableFlightData } = this.state;

    (availableFlightData as AvailableBookingData).categorized_aircrafts.forEach((category) => {
        category.aircrafts.sort((elem1:Aircraft, elem2:Aircraft) => {
            if (sortOption === 'price') {
                const priceA = elem1.quote.price;
                const priceB = elem2.quote.price;
                return priceA - priceB;
            } else { 
                const sumA = elem1.quote.segments.reduce((acc, segment) => acc + segment[sortOption], 0);
                const sumB = elem2.quote.segments.reduce((acc, segment) => acc + segment[sortOption], 0);
                return sumA - sumB;
            }
        });
    });

    this.setState({ availableFlightData });
}

  handleFilterModalOpenAndClose=(isOpen:boolean)=>{
    this.setState({isFilterModalVisible:isOpen})
  }


  getAircrafts=async(formData=this.state.formData,pageNumber=this.state.pageNumber)=>{
    const {numberOfFlights} = this.state;
    const headers = {
      'Content-Type': configJSON.validationApiContentType,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAvailableFlightCallApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAvailableFlightDataEndPoint}?page=${pageNumber}&per=${numberOfFlights}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

  handleBookingNavigation=(booking_id:string)=>{
    handleNavigation(`/OrderManagement?booking_id=${booking_id}`,this.props,this.send);
  }   

handleLoadMore=(page_number:number,parentIndex:number)=>{
  const {formData,pageStore} = this.state;
  this.getAircrafts(formData,page_number);
  this.setState({pageNumber:page_number,isMoreLoading:true,loadMoreIndex:parentIndex,pageStore:this.handlePageIndex(pageStore as number[],parentIndex)});
}

  handleLoadingText=(isMoreLoading:boolean)=>{
   return isMoreLoading ? 'Loading...' : 'Load More'
  }

  handleIsDisabled=(numberOfFlights:number,aircrafts_count:number,isMoreLoading:boolean)=>{
    return numberOfFlights>=aircrafts_count || isMoreLoading;
  }

  handlePageIndex=(array:number[],index:number)=>{
    array[index] = array[index]+1;
    return array;
  }

  handleCombineTime=(departure_date:Date,departure_time:Date)=>{
    const departureDate = moment(departure_date, 'YYYY-MM-DD');
    const departureTime = moment(departure_time, 'HH:mm');
    return departureDate.clone().set({
      'hour': departureTime.hours(),
      'minute': departureTime.minutes(),
    })
  }

  postNewBooking=async(aircarftData:Aircraft)=>{
    const token = await getStorageData('token') || null;
    this.setState({loadMoreText:'creating booking',loader:true});
    const newBookingData = this.handlePostNewBookingData(aircarftData)
    const headers = {
      'Content-Type': configJSON.validationApiContentType,
      'platform':origin,
      'token':token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postNewBookingCallApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postNewBookingEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(newBookingData)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

    handlePostNewBookingData=(aircarftData:Aircraft)=>{
      const {availableFlightData:{categorized_aircrafts}} = this.state as {availableFlightData:AvailableBookingData,expandedRows:ExpandRowsType}
      const {formType} = this.state;
      const {quote:{price},id} = aircarftData;
      const post_data = {
        data: {
          attributes:{
            trip_type:(formType as number)+1,
            flight_price:price,
            asearchhit : id,
            asearch : categorized_aircrafts[0].asearch_id,
            order_items_attributes: this.handleSegmentsData(aircarftData),
            vendor_attributes: this.handleVendorAttributes(aircarftData)
          }
        }
      }

      return post_data;
    }

    handleVendorAttributes=(aircarftData:Aircraft)=>{
      const {quote:{sellerCompany:{id,displayName,contactInfo:{emails,phone,mobilePhone,url,office,address}}},startPosition:{icao}} = aircarftData;
      let vendor_data = {
        avinode_seller_company_id: id,
        name: displayName,
        emails,
        phone_number: phone,
        mobile_number: mobilePhone,
        website: url,
        office,
        address,
        icao_designator: icao
      }

      return vendor_data;
    }

    handleSegmentsData=(aircarftData:Aircraft)=>{
      const {quote:{segments,lift,sellerPrice}} = aircarftData;
      let newArray = segments.map((eachSegment,index:number)=> this.handleEachSegmentDataProivder(eachSegment,index,lift,sellerPrice))
      return newArray;
    }

    handleEachSegmentDataProivder=(eachSegment:SegmentType,index:number,lift:Lift,sellerPrice:SellerPriceWithoutCommission)=>{
      const {formData:{data:{legs}}} = this.state as {formData:FormDataType};
      const {startAirport,endAirport,departureDateTime:{dateTimeLocal},arrivalDateTime,fuelStopCount} = eachSegment;
      const {id, aircraftType, aircraftTail} = lift;
      const {price} = sellerPrice;
      return {
        avinode_from_airport_id: startAirport.id,
        avinode_to_airport_id: endAirport.id,
        avinode_aircraft_id: id,
        departure_date: dateTimeLocal,
        arrival_date: arrivalDateTime.dateTimeLocal,
        no_of_passengers: legs[index].attributes.passenger_configuration,
        tail_number:aircraftTail,
        aircraft_type:aircraftType,
        no_of_stops:fuelStopCount,
        vendor_charged:price,
      }
    }

    handleSuccessCreateBookingResponse=(responseJson:OrderCreateResType)=>{
      const {order:{meta:{message},data:{id}}} = responseJson;
      if(message === 'Order created successfully'){
        this.handleBookingNavigation(id)
      }
    }

    handleInfoToggler=(isInfoToggled=false)=>{
      this.setState({isInfoToggled});
    }

    handleIsAircarftInfoVisble=(isViewFlightDetailsOpen=false)=>{
      this.setState({isViewFlightDetailsOpen});
    }

    handleViewDetails=(aircarft_id:string,index1:number,index2:number,header_name:string)=>{
      const {aircarftCatalogue} = this.state;
      this.setState({addInfoObject:{index1,index2,header_name}});
      this.handleIsAircarftInfoVisble(true);
      if(aircarftCatalogue[aircarft_id] === undefined){
        this.handleAircarftInfoLoading(true);
        this.getEachAircraftDetails(aircarft_id);
        return;
      }
      this.setState({activeCatalogueId:aircarft_id});
    }

    getEachAircraftDetails=async(aircarft_id:string)=>{
      const header = {
        "token" : await getStorageData('token')
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getAircraftCatApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getEachAircraftDetailsEndPoint + `/${aircarft_id}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleCatalogueFlightRes=(responseJson:{data:CatalogueAircarft})=>{
      const {aircarftCatalogue} = this.state;
      if(responseJson.data){
        const {data:{id}} = responseJson;
        let catData = {...aircarftCatalogue};
        catData[id] = responseJson.data;
        this.setState({aircarftCatalogue:catData,activeCatalogueId:id});
      }else{
        handleTriggerAlertMessage(this.send,'Something went wrong','error');
        this.handleIsAircarftInfoVisble();
      }
      this.handleAircarftInfoLoading();
    }

    backToAircrafts=()=>{
      this.handleIsAircarftInfoVisble(false);
      this.setState({addInfoObject:{index1:null,index2:null,header_name:''}});
    }

    handleAircarftInfoLoading=(isLoadingAircarftInfo=false)=>{
      this.setState({isLoadingAircarftInfo});
    }

  // Customizable Area End
}
