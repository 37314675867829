import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {handleNavigation} from "../../../components/src/Navigator";
import {setStorageData} from "../../../framework/src/Utilities";
import { loginRoute } from "../../../components/src/utils";
import Cookies from 'js-cookie';


export interface Airports{
  "id": string,
  "city": string,
  countryCode:string,
  code:string,        
  "name": string,
}

interface AirportType{
    
  "id": string,
  "name": string,
  "city": string,
  "country": {
      "code": string,
      "name": string,
  },
  countryCode:string,
  code:string,
  "icao": string,
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  isDataLoading:boolean;
  isAutcompleteMenuOpen:boolean;
  isPasswordVisible: boolean;
  isConfirmPasswordVisible: boolean;
  isCreateProfileFormVisible: boolean;
  isPasswordErrorsVisible:boolean;
  token:string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  focusLabel:string,
  searchedAirports:Airports[]
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createNewAccountApiCallId: string = "";
  createNewProfileApiCallId:string = '';
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  formikMethods: any = null;
  delayTimer : NodeJS.Timeout|string="";
  getAirportsAPICallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      isAutcompleteMenuOpen:false,
      isDataLoading:false,
      isCreateProfileFormVisible: false,
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
      isPasswordErrorsVisible:false,
      token:'',
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      focusLabel:'',
      searchedAirports:[]
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.validationApiCallId:
            this.validationApiHandler(responseJson);
            break;

          case this.createAccountApiCallId:
            if (!responseJson.errors) {
              const msg: Message = new Message(
                getName(MessageEnum.AccoutResgistrationSuccess)
              );

              msg.addData(
                getName(MessageEnum.NavigationPropsMessage),
                this.props
              );

              this.send(msg);
            } else {
              //Check Error Response
              this.parseApiErrorResponse(responseJson);
            }

            this.parseApiCatchErrorResponse(errorReponse);

            break;

          case this.createNewAccountApiCallId:
            this.handleCreateAccountResponse(responseJson);
            break;
          
          case this.createNewProfileApiCallId:
            this.handleCreateNewProfileResponse(responseJson);
            break;  

          case this.getAirportsAPICallId:
            this.handleAirportsResponse(responseJson);
            break;

          default:
            break;
        }
      }
    }
    this.navigationPayLoadMessageHandler(message);
    this.CountryCodeMessageHandler(message);

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.handleCreateProfileFormOpener()
  }

  validationApiHandler = (responseJson: any) => {
    this.arrayholder = responseJson.data;

    if (this.arrayholder && this.arrayholder.length !== 0) {
      let regexData = this.arrayholder[0];

      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(regexData.password_validation_regexp);
      }

      if (regexData.password_validation_rules) {
        this.setState({
          passwordHelperText: regexData.password_validation_rules
        });
      }

      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  };

  navigationPayLoadMessageHandler = (message: Message) => {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }
  };

  CountryCodeMessageHandler = (message: Message) => {
    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      let selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
  };

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  // Logical Functions for Web

  handlePasswordVisible = (
    passwordStateName: "isPasswordVisible" | "isConfirmPasswordVisible"
  ) => {
    this.setState(
      prevState =>
        ({
          [passwordStateName]: !prevState[passwordStateName]
        } as Pick<S, typeof passwordStateName>)
    );
  };

  handleCreateProfileFormOpener=()=>{
      const {history : {location:{state : {navigationBarTitleText}}}} = this.props as Props & {history : any};
      if(navigationBarTitleText.token){
        this.setState({isCreateProfileFormVisible:true,token:navigationBarTitleText.token});
      }else{
        this.setState({isCreateProfileFormVisible:false});
      }  
  }

  isCreateAccountGrey = (
    password: string,
    confirmPassword: string,
    email: string
  ) => {
    if (password.length && confirmPassword.length && email.length) {
      return true;
    }
    return false;
  };

  createNewAccount = async (values: any) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createNewAccountApiCallId = requestMessage.messageId;

    let formData = new FormData();

    for (let key in values) {
      formData.append(`data[attributes][${key}]`, values[
        key as keyof typeof values
      ] as string);
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createProfileApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createNewProfile=(values:any)=>{
    const headers = {
      token : this.state.token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createNewProfileApiCallId = requestMessage.messageId;
    let formData = this.profileDataAppender(values);
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createNewAccountApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  profileDataAppender = (values:any)=>{
    const formDataKey = (key:string) => `data[attributes][${key}]`
    const {fullName,countryCode,preferredAirport,phoneNumber} = values;
    let formData = new FormData();
    formData.append(formDataKey(`data[type]`),'email_account');
    formData.append(formDataKey(`full_name`),this.removeExtraSpaces(fullName));
    formData.append(formDataKey(`country_code`),'+' + countryCode);
    formData.append(formDataKey(`phone_number`),phoneNumber);
    formData.append(formDataKey(`preferred_departure_airport`),preferredAirport?.name);
    return formData;
  }

  removeExtraSpaces=(str:string = '')=> {
    return str.replace(/\s+/g, ' ').trim();
}

  handleNavigationToLogin=()=>{
        const msg: Message = new Message(
          getName(MessageEnum.NavigationMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.NavigationTargetMessage), `${loginRoute}`);
        this.send(msg);
  }

  // HandleFunctions
  handleCreateAccountResponse = (responseJson: any) => {
    if (responseJson.errors) {
      if (
        responseJson.errors[0].account ===
        "Account already exist with provided email"
      ) {
        this.formikMethods.setFieldError("email", "Email already exists*");
      }
    } else {
      if (responseJson.meta.message === "Account has been created succesfuly") {
        this.setState({ isCreateProfileFormVisible: true, token : responseJson.meta.token });
        const {history : {replace}} = this.props as any;
        replace({
          state: { navigationBarTitleText: {token:responseJson.meta.token} } // If you want to update the state
        });
      }
    }
  };

  handleCreateNewProfileResponse=(responseJson:any)=>{
    if(responseJson.errors){
      this.handleCreateProfileFailResponse(responseJson);
    }else if(responseJson.meta.message === 'Profile created successfully'){
      this.handleCreateProfileSuccessResponse(responseJson);
    }
  }

  handleCreateProfileFailResponse=(responseJson:any)=>{
      if(responseJson.errors[0].full_phone_number){
        this.formikMethods.setFieldError("phoneNumber", responseJson.errors[0].full_phone_number);
      }else{
        this.formikMethods.setFieldError("email", 'Something Went Wrong');
      }
  }

  handleCreateProfileSuccessResponse=async(responseJson:any)=>{
    let {data : {attributes : {full_name , photo}}} = responseJson;
      await setStorageData('token',this.state.token);
      await setStorageData('photo',photo);
      await setStorageData('name',full_name);
      await setStorageData('role','user');
      Cookies.set('status', 'in', { expires: 7 });
      handleNavigation('/',this.props,this.send);
  }

  handleLabelFocus=(focusLabel='')=>{
    this.setState({focusLabel:focusLabel});
  }

  handleIsPasswordErrorsVisible=(isVisible:boolean)=>{
    this.setState({isPasswordErrorsVisible:isVisible});
  }

  handleNavigationToTerms=(type:'terms' | 'policy')=>{
    handleNavigation(`/TermsAndPolicy?type=${type}`,this.props,this.send);
  }

  getOptionLabel = (option:{name:string|null,city:string|null,code:string|null})=>{
    if(option.city){
      return `${option.code} - ${option.name}, ${option.city}`
    }
    else{
      return option.name
    }
  }

  handleAirportSearch = (value: string) => {
    clearTimeout(this.delayTimer)
    this.delayTimer = setTimeout(() => {
        clearTimeout(this.delayTimer);
        this.handleAPICallForAirports(value)
    }, 500);
  }

  handleAPICallForAirports = (changedValue:string)=>{
    if(changedValue.length > 2){
      this.getAirports(changedValue)
    }else{
      this.setState({searchedAirports:[]});
    }
  }

  getAirports = (value:string) => {
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAirportsAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.airportsAPIEdnpoint}=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAirportsResponse = (responseJson:{airports:AirportType[]})=>{
    const {airports} = responseJson;
    let airportManipulatedData = airports.map((item:AirportType)=>{
      return {name:item.name,city:item.city,countryCode:item.country.code,code:item.icao,id :item.id}
    })
  
    this.setState({searchedAirports:airportManipulatedData})
  }

  handleChangeC = (check:string) => {
    switch (check) {
      case "success":
        return "#4CAF50"; 
        case "warning":
        return "#FAFAFA";
      default:
        return "#000000"; 
    }
  }
  // Customizable Area End
}
